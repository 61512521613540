const emailReg = /[A-Za-z0-9._-]+@([A-Za-z0-9_-]+[.])+[A-Za-z0-9_-]{2,4}/;

export const emailValidate = (email) => {
    if(!email || !email.match(emailReg)){
        return false;
    }
    return true;
}

// export const minBirthDate = () => {
//     let today = new Date();
//     let dd = today.getDate();
//     let mm = today.getMonth()+1; 
//     let yyyy = today.getFullYear()-18;
//     if(dd<10){
//         dd='0'+dd;
//     } 
//     if(mm<10){
//         mm='0'+mm;
//     }

//     return yyyy+'-'+mm+'-'+dd;
// }

export const minBirthDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    } 
    if(mm<10){
        mm='0'+mm;
    }
    return yyyy+'-'+mm+'-'+dd;
}