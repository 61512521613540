/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmployeur = /* GraphQL */ `
  mutation CreateEmployeur(
    $input: CreateEmployeurInput!
    $condition: ModelEmployeurConditionInput
  ) {
    createEmployeur(input: $input, condition: $condition) {
      id
      name
      ApplicationEmployeur {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeur = /* GraphQL */ `
  mutation UpdateEmployeur(
    $input: UpdateEmployeurInput!
    $condition: ModelEmployeurConditionInput
  ) {
    updateEmployeur(input: $input, condition: $condition) {
      id
      name
      ApplicationEmployeur {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeur = /* GraphQL */ `
  mutation DeleteEmployeur(
    $input: DeleteEmployeurInput!
    $condition: ModelEmployeurConditionInput
  ) {
    deleteEmployeur(input: $input, condition: $condition) {
      id
      name
      ApplicationEmployeur {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAgence = /* GraphQL */ `
  mutation CreateAgence(
    $input: CreateAgenceInput!
    $condition: ModelAgenceConditionInput
  ) {
    createAgence(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAgence = /* GraphQL */ `
  mutation UpdateAgence(
    $input: UpdateAgenceInput!
    $condition: ModelAgenceConditionInput
  ) {
    updateAgence(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAgence = /* GraphQL */ `
  mutation DeleteAgence(
    $input: DeleteAgenceInput!
    $condition: ModelAgenceConditionInput
  ) {
    deleteAgence(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      email
      prenom
      nom
      sujet
      inscription
      role
      employeur
      dateNaissance
      token
      statut
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      email
      prenom
      nom
      sujet
      inscription
      role
      employeur
      dateNaissance
      token
      statut
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      email
      prenom
      nom
      sujet
      inscription
      role
      employeur
      dateNaissance
      token
      statut
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
