import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { AmplifyProvider } from "@aws-amplify/ui-react";
import config from './aws-exports';
import theme from './ui-components/studioTheme';
// import { AuthenticatorCustom } from "./pages/auth/AuthenticatorCustom";
import { RGPDForm } from "./pages/form/RGPDForm";
import "@aws-amplify/ui-react/styles.css";
import './index.css';
// import { ImportAgencies } from "./components/ImportAgencies";

Amplify.configure(config);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AmplifyProvider theme={theme}>
    <Router>
        <Routes>
          {/* <Route path="/login" element={<AuthenticatorCustom/>}/> */}
          <Route path="/*" element={<RGPDForm/>}/>
          {/* <Route path="/import" element={<ImportAgencies/>}/> */}
        </Routes>
    </Router>
  </AmplifyProvider>
);
