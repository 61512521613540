/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex } from "@aws-amplify/ui-react";
export default function CritButton(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      height="51px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "CritButton")}
      {...rest}
    >
      <Button
        display="flex"
        gap="0"
        direction="row"
        height="51px"
        justifyContent="center"
        alignItems="center"
        grow="1"
        basis="51px"
        position="relative"
        backgroundImage="linear-gradient(90deg, rgba(255,106,20,1), rgba(213,0,50,1))"
        size="default"
        isDisabled={false}
        variation="primary"
        children="Crit Button"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
