import { Button, Card, Flex, Heading } from '@aws-amplify/ui-react';
import styles from './FormContainer.module.css';

export default function FormContainer({prev,children}){
    return(
        <Card className={styles.card}>
            {prev ? 
                <Button className={styles.btn} variation="link" loadingText="" onClick={prev} ariaLabel="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" fill="none" viewBox="0 0 12 18">
                        <path stroke="#333F48" d="M10.533 1 2 9l8.533 8"/>
                    </svg>
                    Retour
                </Button>
                :
                null }  
            <Flex className={styles.container}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="2rem"
            >
            <Heading level="1">Formulaire pour exercer vos droits sur&nbsp;vos données&nbsp;personnelles</Heading>
            {children}
            </Flex>
        </Card>
   )
}