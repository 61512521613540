import { View, Image } from '@aws-amplify/ui-react';
import logo from "../../../assets/logo-crit.svg";
import styles from './FormHeader.module.css';

export default function FormHeader(){
    return (
        <View textAlign="center">
            <Image alt="Crit logo" src={logo}  className={styles.logo}/>
        </View>
    )
}
