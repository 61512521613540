import React from 'react';
import { Card, Flex, Text } from "@aws-amplify/ui-react";
import styles from './errors.module.css';
import image from '../../assets/404.png';

const Error404 = () => (
    <Card className={styles.card}>
        <Flex className={styles.container}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="2rem"
            >
                <img src={image} className={styles.img} alt="error 404 "/>
                <Text textAlign="center" fontSize='18px'>
                    Page Not Found
                </Text>
            </Flex>
    </Card>
)

export { Error404 };