import { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { getEmployeurs } from '../../../graphql/custom-queries';
import { SelectField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../../../components/form/container/FormContainer';
import FormFields from '../../../components/form/FormFields';
import FormSubject from '../../../components/form/FormSubject';
import CritButton from '../../../ui-components/CritButton';
import CritMentionLegalesPartenaireCollaborateur from '../../../ui-components/CritMentionLegalesPartenaireCollaborateur';
import { emailValidate } from '../../../utils/utils';
import { useFormContext } from '../../../contexts/form-context';
import { minBirthDate } from '../../../utils/utils';

const FormCollaborateur = () => {
    const { formData, dispatch } = useFormContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(!formData.role){
            navigate('/');
        }
    })

    const fields = [
        {
            label:"Nom :",
            name:"nom",
            type:"text",
            error:"Veuillez renseigner votre nom",
            value:formData.nom
        },
        {
            label:"Prénom :",
            name:"prenom",
            type:"text",
            error:"Veuillez renseigner votre prénom",
            value:formData.prenom
        },
        {
            label:"Date de naissance :",
            name:"dateNaissance",
            type:"date",
            error:"Veuillez renseigner une date de naissance valide",
            value:formData.dateNaissance,
            max:minBirthDate()
        },
        {
            label:"Adresse e-mail de contact :",
            name:"email",
            type:"text",
            error:"Veuillez renseigner une adresse e-mail valide",
            value:formData.email
        },
    ];
    const [employeur, setEmployeur] = useState(formData.employeur ? formData.employeur : '');
    const [employeurs, setEmployeurs] = useState([]);
    const [error, setError] = useState({
        nom: false,
        prenom: false,
        email: false,
        employeur: false,
        sujet: false
    });
    useEffect(()=> {
        const getEmployeurOptions = async () => {
            let listEmployeurs = await API.graphql({ query: getEmployeurs });
            listEmployeurs = listEmployeurs?.data?.listEmployeurs?.items;
            setEmployeurs(listEmployeurs);
        }
        getEmployeurOptions();
    }, [])

    const handleSubmit = ()=>{
        if(!formData.nom || !formData.prenom || (!formData.dateNaissance || formData.dateNaissance > minBirthDate()) || !formData.email || !emailValidate(formData.email) || !formData.employeur || !formData.sujet || formData.sujet === "rectification : "){
            if(!formData.nom){
                setError(error => ({
                    ...error,
                    nom: true
                }));
            }
            if(!formData.prenom){
                setError(error => ({
                    ...error,
                    prenom: true
                }));
            }
            if(!formData.dateNaissance || formData.dateNaissance > minBirthDate()){
                setError(error => ({
                    ...error,
                    dateNaissance: true
                }));
            }
            if(!formData.email || !emailValidate(formData.email)){
                setError(error => ({
                    ...error,
                    email: true
                }));
            }
            if(!formData.employeur){
                setError(error => ({
                    ...error,
                    employeur: true
                }));
            }
            if(!formData.sujet){
                setError(error => ({
                    ...error,
                    sujet: true
                }));
            }
            return;
        }
        navigate('/success');
    }

    const handleChange = (e)=>{
        if(e.target.value === ""){
            if(error[e.target.name] === false){
                setError(error => ({
                    ...error,
                    [e.target.name]: true
                }));
            }   
        }else{
            if(error[e.target.name] === true){
                setError(error => ({
                    ...error,
                    [e.target.name]: false
                }));
            }   
        }
        dispatch({action: 'SET_FORM_DATA', payload: { [e.target.name]: e.target.value}})
    }

    const handleSelect = (e) => {
        handleChange(e);
        setEmployeur(e.target.value);
    }

    const modifySubject = useCallback((newSubject) => {
        if(newSubject === 'rectification'){
            return;
        }
        dispatch({action: 'SET_FORM_DATA', payload: { sujet: newSubject}})
        if(error.sujet === true  && formData.sujet !== ""){
            setError(error => ({
                ...error,
                sujet: false
            }));
        }   
    }, [formData,error,dispatch])

    return (
        <FormContainer prev={()=>navigate('/')}>
            <FormFields fields={fields} onChange={handleChange} errors={error}/>
            <SelectField className='requiredField' label="Employeur" onChange={handleSelect} value={employeur} name="employeur" hasError={error.employeur} errorMessage="Veuillez renseigner renseigner un employeur">
                <option key="default" value="">---</option>
                {
                    employeurs.length > 0 ? 
                        employeurs.map( employeur => <option id={employeur.id} key={employeur.id} value={employeur.id}>{employeur.name}</option>)
                    :
                        null
                }
            </SelectField>
            <FormSubject modifySubject={modifySubject} formData={formData} error={error.sujet} />
            <CritButton textAlign="center" className='crit-button' overrides={{Button:{onClick:handleSubmit,children:'Envoyer votre demande'}}}/>
            <CritMentionLegalesPartenaireCollaborateur/>
        </FormContainer>
    )
}

export { FormCollaborateur };