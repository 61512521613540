import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckboxField, Text, Flex } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../../../components/form/container/FormContainer';
import FormError from '../../../components/form/error/FormError';
import FormSubject from '../../../components/form/FormSubject';
import FormFields from '../../../components/form/FormFields';
import CritTextField from '../../../ui-components/CritTextField';
import CritButton from '../../../ui-components/CritButton';
import CritMentionLegalesCandidatInterimaire from '../../../ui-components/CritMentionLegalesCandidatInterimaire';
import { emailValidate, minBirthDate } from '../../../utils/utils';
import { useFormContext } from '../../../contexts/form-context';

const FormCandidat = () => {
    const { formData, dispatch } = useFormContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(!formData.role){
            navigate('/');
        }
    })

    const fields = [
        {
            label:"Nom :",
            name:"nom",
            type:"text",
            error:"Veuillez renseigner votre nom",
            value:formData.nom
        },
        {
            label:"Prénom :",
            name:"prenom",
            type:"text",
            error:"Veuillez renseigner votre prénom",
            value:formData.prenom
        },
        {
            label:"Date de naissance :",
            name:"dateNaissance",
            type:"date",
            error:"Veuillez renseigner une date de naissance valide",
            value:formData.dateNaissance,
            max:minBirthDate()
        },
        {
            label:"Adresse e-mail utilisée lors de votre inscription :",
            name:"email",
            type:"text",
            error:"Veuillez renseigner une adresse e-mail valide",
            value:formData.email
        },
    ];
    
    const [error, setError] = useState({
        nom: false,
        prenom: false,
        dateNaissance: false,
        email: false,
        inscription: false,
        agence: false,
        evenement: false,
        autre: false,
        sujet: false
    })
    const [checkboxField,setCheckboxField] = useState({
        critJob: formData.inscription ? (formData.inscription["critJob"] ? true : false) : false,
        site: formData.inscription ? (formData.inscription["site"] ? true : false) : false,
        agence: formData.inscription ? (formData.inscription["agence"] ? true : false) : false,
        evenement: formData.inscription ? (formData.inscription["evenement"] ? true : false) : false,
        autre: formData.inscription ? (formData.inscription["autre"] ? true : false) : false
    });
    const checkbox = useRef();

    const checkBoxList = [
        {
            id: "critJob",
            text: "www.crit-job.com",
            hasInput: false
        },
        {
            id: "site",
            text: "Site de recrutement",
            hasInput: false
        },
        {
            id: "agence",
            text: "Agence(s)",
            hasInput: true,
            placeholder: "Merci de préciser"
        },
        {
            id: "evenement",
            text: "Evénement",
            hasInput: true,
            placeholder: "Merci de préciser le type et la localisation"
        },
        {
            id: "autre",
            text: "Autre",
            hasInput: true,
            placeholder: "Merci de préciser"
        }
    ]

    const handleSubmit = ()=>{
        if(!formData.nom || !formData.prenom || (!formData.dateNaissance || formData.dateNaissance > minBirthDate()) || !formData.email || !emailValidate(formData.email) ||
            !formData.inscription || !Object.keys(formData.inscription).length > 0 || formData.inscription.agence === "" || formData.inscription.evenement === "" || 
            formData.inscription.autre === "" || !formData.sujet || formData.sujet === "rectification : "){
            
            if(!formData.nom){
                setError(error => ({
                    ...error,
                    nom: true
                }));
            }
            if(!formData.prenom){
                setError(error => ({
                    ...error,
                    prenom: true
                }));
            }
            if(!formData.dateNaissance || formData.dateNaissance > minBirthDate()){
                setError(error => ({
                    ...error,
                    dateNaissance: true
                }));
            }
            if(!formData.email || !emailValidate(formData.email)){
                setError(error => ({
                    ...error,
                    email: true
                }));
            }
            if(!formData.inscription || !Object.keys(formData.inscription).length > 0){
                setError(error => ({
                    ...error,
                    inscription: true
                }));
            }else {
                if(formData.inscription.agence === "") {
                    setError(error => ({
                        ...error,
                        agence: true
                    }));
                }
                if(formData.inscription.evenement === "") {
                    setError(error => ({
                        ...error,
                        evenement: true
                    }));
                }
                if(formData.inscription.autre === "") {
                    setError(error => ({
                        ...error,
                        autre: true
                    }));
                }
            }
            if(!formData.sujet){
                setError(error => ({
                    ...error,
                    sujet: true
                }));
            }
            return;
        }
        navigate('/success');
    }

    const modifyInscription = (key,val)=>{
        if(val === false){ 
            dispatch({action: 'DELETE_INSCRIPTION', payload: key});
        }else{ 
            dispatch({action: 'MODIFY_INSCRIPTION', payload: { [key]:val }})
            if(error.inscription === true){
                setError(error => ({
                    ...error,
                    inscription: false
                }));
            }   
        }
        setCheckboxField(prevState => ({
            ...prevState,
            [key]: val
        }));
    }

    const handleInscriptionChange = (e)=>{
        if(!e.target.checked){
            dispatch({action: 'DELETE_INSCRIPTION', payload: e.target.name});
            setCheckboxField(prevState => ({
                ...prevState,
                [e.target.name]: false
            }));
            return;
        }
        dispatch({action: 'MODIFY_INSCRIPTION', payload: { [e.target.name]: "" }})
        setCheckboxField(prevState => ({
            ...prevState,
            [e.target.name]: true
        }));
        if(error.inscription === true){
            setError(error => ({
                ...error,
                inscription: false
            }));
        }  
    }

    const handleChange = (e)=>{
        dispatch({action: 'SET_FORM_DATA', payload: { [e.target.name]: e.target.value}})
        if(error[e.target.name] === true){
            setError(error => ({
                ...error,
                [e.target.name]: false
            }));
        }
    }

    const handleTextInput = (e)=>{
        if(e.target.value === ""){
            if(error[e.target.name.replace("Text","")] === false){
                setError(error => ({
                    ...error,
                    [e.target.name.replace("Text","")]: true
                }));
            }   
        }else{
            if(error[e.target.name.replace("Text","")] === true){
                setError(error => ({
                    ...error,
                    [e.target.name.replace("Text","")]: false
                }));
            }   
        }
        dispatch({action: 'MODIFY_INSCRIPTION', payload: { [e.target.name.replace("Text","")]: e.target.value }})
    }

    const modifySubject = useCallback((newSubject) => {
        if(newSubject === 'rectification'){
            return;
        }
        dispatch({action: 'SET_FORM_DATA', payload: { sujet: newSubject}})
        if(error.sujet === true && formData.sujet !== ""){
            setError(error => ({
                ...error,
                sujet: false
            }));
        }   
    }, [formData,error,dispatch])

    return (
        <FormContainer prev={()=>navigate('/')}>
            <FormFields fields={fields} onChange={handleChange} errors={error}/>
            <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="5px"
            >
            <Text className="requiredField multipleChoices amplify-label">{"Par quel(s) moyen(s) vous êtes-vous inscrit(e) ?"}</Text>
            
            {checkBoxList.map((obj)=>{
                if(obj.hasInput){
                    return(
                    <React.Fragment key={obj.id}>
                        <CheckboxField ref={checkbox} checked={checkboxField[obj.id]} name={obj.id} onChange={handleInscriptionChange} label={obj.text} hasError={error.inscription}></CheckboxField>
                        {checkboxField[obj.id] ? 
                        <CritTextField overrides={{TextField:{ name:`${obj.id}Text`,onChange:handleTextInput,defaultValue:formData.inscription ? (formData.inscription[obj.id] ? formData.inscription[obj.id] : "") : "", labelHidden:true, placeholder:obj.placeholder,hasError:error[obj.id], errorMessage:'Ne laissez pas ce champ vide'}}} /> 
                        : null}
                    </React.Fragment> );
                }else{
                    return (<CheckboxField key={obj.id} checked={checkboxField[obj.id]} name={obj.id} onChange={(e)=>{modifyInscription(e.target.name,e.target.checked)}} label={obj.text} hasError={error.inscription}></CheckboxField>);
                }
            })}
            {
                error.inscription ?
                <FormError type="general">Veuillez renseigner un type d'inscription</FormError> : null            
            }
            </Flex>
            <FormSubject modifySubject={modifySubject} formData={formData} error={error.sujet}/>
            <CritButton textAlign="center" className='crit-button' overrides={{Button:{onClick:handleSubmit,children:'Envoyer votre demande'}}}/>
            <CritMentionLegalesCandidatInterimaire/>
        </FormContainer>
    )
}

export { FormCandidat };