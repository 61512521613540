/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function FailedIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "FailedIcon")}
      {...rest}
    >
      <View
        width="96px"
        height="96px"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "icons_pblms 1")}
      >
        <Icon
          width="62.5390625px"
          height="73.728759765625px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 62.5390625,
            height: 73.728759765625,
          }}
          paths={[
            {
              d: "M9.35063 43.965C9.35063 22.7869 26.5256 5.61191 47.7019 5.61191C52.7959 5.60971 57.8395 6.62123 62.5387 8.58754C55.7076 3.02167 47.1628 -0.0120995 38.3512 3.62682e-05C17.175 3.62682e-05 1.282e-08 17.175 1.282e-08 38.3513C-0.000195244 45.907 2.23002 53.2946 6.41107 59.588C10.5921 65.8814 16.5379 70.8006 23.5031 73.7288C19.0764 70.1348 15.5085 65.5973 13.0599 60.4479C10.6114 55.2984 9.3441 49.667 9.35063 43.965L9.35063 43.965Z",
              fill: "rgba(255,106,20,0.25)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="13.39%"
          bottom="9.8%"
          left="8.62%"
          right="26.24%"
          {...getOverrideProps(overrides, "Vector34441280")}
        ></Icon>
        <Icon
          width="80.4375px"
          height="80.4376220703125px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 80.4375,
            height: 80.4376220703125,
          }}
          paths={[
            {
              d: "M40.2224 0.000109314C32.2671 -0.00135538 24.4901 2.35669 17.8752 6.77594C11.2603 11.1952 6.10468 17.4771 3.0606 24.8269C0.0165263 32.1768 -0.779258 40.2644 0.773919 48.0666C2.3271 55.8688 6.15945 63.0351 11.7862 68.6589C27.4912 84.3639 52.9556 84.3639 68.6587 68.6589C84.3618 52.9539 84.3655 27.4914 68.6587 11.7864C64.933 8.03986 60.5016 5.06917 55.6206 3.04611C50.7397 1.02304 45.506 -0.012247 40.2224 0.000109314L40.2224 0.000109314ZM40.2224 76.7045C20.1112 76.7045 3.73492 60.3339 3.73492 40.2226C3.73492 20.1114 20.1112 3.74261 40.2224 3.74261C60.3337 3.74261 76.7043 20.1114 76.7043 40.2226C76.7043 60.3339 60.3337 76.7045 40.2224 76.7045Z",
              fill: "rgba(51,63,72,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="11.45%"
          bottom="4.77%"
          left="6.66%"
          right="9.55%"
          {...getOverrideProps(overrides, "Vector34441281")}
        ></Icon>
        <Icon
          width="29.05078125px"
          height="29.01837158203125px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 29.05078125,
            height: 29.01837158203125,
          }}
          paths={[
            {
              d: "M28.5555 1.14412C28.2293 0.802733 27.7809 0.604689 27.3088 0.593441C26.8368 0.582192 26.3795 0.758657 26.0374 1.08412L14.5999 11.9854L3.69863 0.54787C3.37142 0.206984 2.92219 0.0100434 2.44978 0.000373571C1.97736 -0.00929627 1.52045 0.169098 1.17957 0.496309C0.838679 0.82352 0.641741 1.27274 0.632071 1.74516C0.622401 2.21758 0.800793 2.67449 1.128 3.01537L12.0293 14.4529L0.591754 25.3541C0.41421 25.5134 0.270466 25.7067 0.169046 25.9226C0.067626 26.1385 0.0105971 26.3725 0.00134165 26.6109C-0.00791376 26.8492 0.0307913 27.087 0.115165 27.3101C0.199538 27.5332 0.32786 27.7371 0.492516 27.9096C0.657173 28.0822 0.854811 28.22 1.07371 28.3147C1.2926 28.4094 1.5283 28.4593 1.76681 28.4612C2.00532 28.4631 2.2418 28.4171 2.4622 28.326C2.6826 28.2348 2.88245 28.1002 3.04988 27.9304L14.478 17.0291L25.3793 28.4666C25.7055 28.8085 26.1541 29.0068 26.6266 29.0179C27.099 29.0289 27.5564 28.8519 27.8983 28.5257C28.2402 28.1995 28.4385 27.7508 28.4496 27.2784C28.4606 26.806 28.2836 26.3485 27.9574 26.0066L17.0655 14.5635L28.503 3.66225C28.8438 3.33525 29.0408 2.88627 29.0507 2.41405C29.0605 1.94184 28.8824 1.48504 28.5555 1.14412L28.5555 1.14412Z",
              fill: "rgba(255,106,20,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="38.23%"
          bottom="31.54%"
          left="33.4%"
          right="36.34%"
          {...getOverrideProps(overrides, "Vector34441282")}
        ></Icon>
      </View>
    </Flex>
  );
}
