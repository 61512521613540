import React, { useEffect, useRef, useState } from 'react';
import { Flex, RadioGroupField, Radio } from '@aws-amplify/ui-react';
import CritTextField from '../../ui-components/CritTextField';

export default function FormSubject({modifySubject, formData, error}){
    const [radioValue, setRadioValue] = useState(formData.sujet ? (formData.sujet.includes('rectification') ? formData.sujet.split(' : ')[0] : formData.sujet ) : '');
    const [radioText, setRadioText] = useState(formData.sujet ? (formData.sujet.includes('rectification') ? formData.sujet.split(': ')[1] : '' ) : '');
    const [showRadioText, setShowRadioText] = useState(formData.sujet ? (formData.sujet.includes('rectification') ? true : false): false);
    const [showErrorRectification, setShowErrorRectification] = useState(false);
    const rectification = useRef();
    
    useEffect(() => {
        if(radioValue === 'rectification' && formData.sujet.includes('rectification ')){
            return;
        }
        modifySubject(radioValue);
    }, [radioValue, modifySubject, formData]);
    
    const radioOptions = {
        'effacement': 'Effacement de vos données personnelles',
        'retrait': 'Retrait des listes de diffusion',
        'rectification': 'Rectification de vos données personnelles ',
        'acces': 'Accès à vos données personnelles',
        'portabilite': 'Portabilité de vos données personnelles'
    }

    const handleChange = (e) => {
        if(e.target.value !== "rectification" && showErrorRectification === true){
            setShowErrorRectification(false);
        }
        setShowRadioText(e.target.value === 'rectification' ? true : false);
        setRadioValue(e.target.value === 'rectification' ? '' : e.target.value);
    }

    const handleInput = (e) => {
        if(e.target.value === ""){
            setRadioText("");
            setRadioValue("");
            setShowErrorRectification(true);
            modifySubject("");
            return;
        }
        setShowErrorRectification(false);
        setRadioText(e.target.value);
        setRadioValue(`rectification : ${e.target.value}`);
    }
    return (
        <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="5px"
    >
            <RadioGroupField className='requiredField' defaultValue={radioValue} onChange={handleChange} label="Objet de votre demande :" name="sujet" hasError={error} errorMessage="Veuillez renseigner l'objet de votre demande">
            {
                Object.entries(radioOptions).map(entry => {
                    const [key, val] = entry;
                    return (
                        key ==="rectification" ? 
                        <React.Fragment key={key}>
                            <Radio ref={rectification} value={key}>{val}</Radio>
                            { showRadioText ? 
                            <CritTextField style={{width:"100%"}} overrides={{TextField:{ defaultValue:radioText, onChange:handleInput, labelHidden:true, placeholder:'Merci de préciser', name:"rectification", hasError:showErrorRectification, errorMessage:"Ne laissez pas ce champ vide" }}} /> 
                            : null }
                        </React.Fragment>
                        : 
                        <Radio ref={rectification} value={key} key={key}>{val}</Radio>
                    )
                })
            }
            </RadioGroupField>
        </Flex>
    )
}