import React, { useEffect, useState } from "react";
import { API } from 'aws-amplify';
import { createApplication } from "../../../graphql/mutations";
import { Card, Flex, Text} from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '../../../ui-components/EmailIcon';
import { useFormContext } from '../../../contexts/form-context';
import styles from './FormSuccess.module.css';

const FormSuccess = () => {
    const { formData, dispatch } = useFormContext();
    const [ isLoaded, setIsLoaded ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(!formData || !formData.role){
            navigate('/');
            return;
        } 
        if(!isLoaded){
            dispatch({action: "CLEAN_FORM"});
            setIsLoaded(true);
        } else {
            insertApplication();
        }
    },[isLoaded])
    async function insertApplication() {
        let input = {
            ...formData,
            statut:"EN_ATTENTE",
            token: uuidv4()
        };
        await API.graphql({ query: createApplication, variables: {input}});
    }
    
    
    
    return (
        <Card className={styles.card}> 
            <Flex className={styles.container}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="2rem"
            >
                    <Text textAlign="center" fontWeight='bold' fontSize='18px'>
                        Une dernière étape est&nbsp;nécessaire !
                    </Text>
                    <Text textAlign="center">
                        Votre demande doit être confirmée.<br/>
                        Veuillez consulter votre messagerie et confirmer votre adresse e&#8209;mail.
                    </Text>
                    <Text textAlign="center" style={{fontSize: '14px'}}>
                        <i>Vous n'avez pas reçu l'e&#8209;mail ? Nous vous invitons à consulter vos spams.</i>
                    </Text>
                <EmailIcon/>
            
            </Flex>
        </Card>
    )
}

export { FormSuccess }