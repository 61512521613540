import React, { useCallback, useEffect, useState } from 'react';
import { Card, Flex, Text } from '@aws-amplify/ui-react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import { applicationByToken, listApplications } from '../../../graphql/queries';
import ValidationIcon from '../../../ui-components/ValidationIcon';
import FailedIcon from '../../../ui-components/FailedIcon';
import styles from './FormConfirm.module.css';
import { updateApplication } from '../../../graphql/mutations';

const FormConfirm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [error,setError] = useState("");
    const [loaded,setLoaded] = useState(false);
    let token = searchParams.get("token");
    
    const confirmationApplication = useCallback(async () => {
        try{
            let application = await API.graphql(graphqlOperation(listApplications, { filter: {token: { eq: token}}}));
            let applicationByTokenVar = await API.graphql(graphqlOperation(applicationByToken, { token }));
            application = applicationByTokenVar?.data?.applicationByToken?.items?.[0] || application?.data?.listApplications?.items?.[0];
            if(application){
                if(application.statut === "EN_ATTENTE"){
                    await API.graphql({ query: updateApplication, variables: {input: { id: application.id, statut: "EN_COURS", _version: application._version++}}});
                } else {
                    setError(<>Votre demande est déjà en cours de traitement.<br/></>);
                }
            }else{
                setError(<>Votre demande n'a pas été trouvée.<br/></>);
            }
        }catch(err){
            console.log(err)
            setError(<>
            Votre adresse e&#8209;mail n'a pas pu être vérifiée.<br/>
            Si vous avez effectuer plusieurs demandes, vérifiez avoir utiliser le lien du dernier mail reçu.<br/>
            Dans le cas échéant, vous pouvez réessayer ou contacter notre Data Protection Officer à l'addresse suivante: dpo@groupe-crit.com.<br/>
            </>);
        }
    },[token]);
    
    useEffect(() => {
        if(!token || token === ""){
            navigate('/');
            return;
        }
        if(loaded === false && token && token !== ""){
            confirmationApplication().then(() =>
                setLoaded(true)
            )
        } 
    }, [confirmationApplication, token, loaded, navigate])

    return(
        <Card className={styles.card}> 
            <Flex className={styles.container}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="2rem"
            >
                { !loaded ? 
                    null :
                    error === "" ? 
                        <React.Fragment>
                            <Text textAlign="center" fontWeight='bold' fontSize='18px'>
                                Nous vous remercions !
                            </Text>
                            <Text textAlign="center">
                                Votre adresse e&#8209;mail a bien été vérifiée.<br/>
                                Nous allons traiter votre demande dans les plus brefs délais.<br/>
                            </Text>
                            <ValidationIcon/>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <Text textAlign="center" fontWeight='bold' fontSize='18px'>
                                Un problème est survenu.
                            </Text>
                            <Text textAlign="center">
                                {error}
                            </Text>
                            <FailedIcon/>
                        </React.Fragment>
            }
            </Flex>
        </Card>
    )
}

export { FormConfirm };