import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../../../components/form/container/FormContainer';
import FormFields from '../../../components/form/FormFields';
import FormSubject from '../../../components/form/FormSubject';
import CritButton from '../../../ui-components/CritButton';
import CritMentionLegalesCandidatInterimaire from '../../../ui-components/CritMentionLegalesCandidatInterimaire';
import { emailValidate } from '../../../utils/utils';
import { useFormContext } from '../../../contexts/form-context';
import { minBirthDate } from '../../../utils/utils';


const FormInterimaire = () => { 
    const { formData, dispatch } = useFormContext();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(!formData.role){
            navigate('/');
        }
    })

    const fields = [
        {
            label:"Nom :",
            name:"nom",
            type:"text",
            error:"Veuillez renseigner votre nom",
            value:formData.nom
        },
        {
            label:"Prénom :",
            name:"prenom",
            type:"text",
            error:"Veuillez renseigner votre prénom",
            value:formData.prenom
        },
        {
            label:"Date de naissance :",
            name:"dateNaissance",
            type:"date",
            error:"Veuillez renseigner une date de naissance valide",
            value:formData.dateNaissance,
            max:minBirthDate()
        },
        {
            label:"Adresse e-mail utilisée lors de votre inscription :",
            name:"email",
            type:"text",
            error:"Veuillez renseigner une adresse e-mail valide",
            value:formData.email
        },
    ];

    const [error, setError] = useState({
        nom: false,
        prenom: false,
        dateNaissance: false,
        email: false,
        sujet: false
    })

    const handleSubmit = ()=>{
        if(!formData.nom || !formData.prenom || (!formData.dateNaissance || formData.dateNaissance > minBirthDate()) || !formData.email || !emailValidate(formData.email) || !formData.sujet || formData.sujet === "rectification : "){
            if(!formData.nom){
                setError(error => ({
                    ...error,
                    nom: true
                }));
            }
            if(!formData.prenom){
                setError(error => ({
                    ...error,
                    prenom: true
                }));
            }
            if(!formData.dateNaissance || formData.dateNaissance > minBirthDate()){
                setError(error => ({
                    ...error,
                    dateNaissance: true
                }));
            }
            if(!formData.email || !emailValidate(formData.email)){
                setError(error => ({
                    ...error,
                    email: true
                }));
            }
            if(!formData.sujet){
                setError(error => ({
                    ...error,
                    sujet: true
                }));
            }
            return;
        }
        navigate('/success');
    }

    const modifySubject = useCallback((newSubject) => {
        if(newSubject === 'rectification'){
            return;
        }
        dispatch({action: 'SET_FORM_DATA', payload: { sujet: newSubject}})
        if(error.sujet === true  && formData.sujet !== ""){
            setError(error => ({
                ...error,
                sujet: false
            }));
        }   
    }, [formData,error,dispatch])

    const handleChange = (e)=>{
        dispatch({action: 'SET_FORM_DATA', payload: { [e.target.name]: e.target.value}})
        if(error[e.target.name] === true){
            setError(error => ({
                ...error,
                [e.target.name]: false
            }));
        }   

    }

    return (
        <FormContainer prev={()=>navigate('/')}>
            <FormFields fields={fields} onChange={handleChange} errors={error}/>
            <FormSubject modifySubject={modifySubject} formData={formData} error={error.sujet}/>
            <CritButton textAlign="center" className='crit-button' overrides={{Button:{onClick:handleSubmit,children:'Envoyer votre demande'}}}/>
            <CritMentionLegalesCandidatInterimaire/>
        </FormContainer>
    )
}

export { FormInterimaire };