import CritTextField from "../../ui-components/CritTextField";

export default function FormFields({fields, onChange, errors}){
    return(
        fields.map((obj, index) => {
            return (
                <CritTextField key={index} className="requiredField" onChange={onChange} overrides={{TextField:{type:obj.type,label:obj.label, name:obj.name, defaultValue: obj.value, placeholder: "", max:obj?.max,  errorMessage:obj.error, hasError:errors[obj.name]}}}/>
            );
        })
    )
}