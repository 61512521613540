import React, { createContext, useContext, useReducer } from "react";

export const formInitData = {
    role: null,
}

export const useFormContext = () => {
    const context = useContext(FormContext);

    if(context === undefined){
        throw new Error('Missing Form Context Provider');
    }

    return context;
}

const verifiedData = {
    candidat: [
        "inscription","nom","prenom","dateNaissance","email","sujet","role"
    ],
    collaborateur:[
        "list","nom","prenom","dateNaissance","email","sujet","role","employeur"
    ],
    interimaire: [
        "nom","prenom","dateNaissance","email","sujet","role"

    ],
    partenaire:[
        "nom","prenom","email","sujet","role"
    ]
}

export const formDataReducer = (previousState, {action, payload}) => {
    switch(action){
        case "SET_FORM_DATA":
            return { ...previousState, ...payload};
        case "MODIFY_INSCRIPTION":
            previousState.inscription = {
                ...previousState['inscription'],
                ...payload
            };
            return { ...previousState };
        case "DELETE_INSCRIPTION":
            delete previousState.inscription[payload];
            return { ...previousState };
        case "CLEAN_FORM":
            Object.keys(previousState).map((key) => {
                if(key === "inscription"){
                    previousState[key] = JSON.stringify(previousState[key])
                }
                //// ON SUPPRIME LES CLES INUTILE AU ROLE CHOISI
                if(!verifiedData[previousState.role].includes(key)){ 
                    delete previousState[key];
                    return null;
                }
                return key;
            })
            return { ...previousState };
        default:
            throw new Error('undefined action');
    }
}

export const FormContext = createContext(formInitData);

export const FormContextProvider = ({children}) => {
    const [ formData, dispatch ] = useReducer(formDataReducer, formInitData);

    return (
        <FormContext.Provider value={{formData, dispatch}}>
            {children}
        </FormContext.Provider>
    )
}