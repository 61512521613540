import { Route, Routes } from 'react-router-dom';
import { FormCandidat } from './candidat/FormCandidat';
import { FormCollaborateur } from './collaborateur/FormCollaborateur';
import { FormInterimaire } from './interimaire/FormInterimaire';
import { FormPartenaire } from './partenaire/FormPartenaire';
import { FormStart } from './start/FormStart';
import { FormSuccess } from './success/FormSuccess';
import { FormContextProvider } from '../../contexts/form-context';
import { FormConfirm } from "./confirm/FormConfirm";
import { Error404 } from '../errors/404';
import FormHeader from '../../components/form/header/FormHeader';


export const RGPDForm = () => (
	<FormContextProvider>
		<FormHeader/>
		<Routes>
			<Route path="/candidat" element={<FormCandidat />} />
			<Route path="/interimaire" element={<FormInterimaire />} />
			<Route path="/collaborateur" element={<FormCollaborateur />} />
			<Route path="/partenaire" element={<FormPartenaire />} />
			<Route path="/success" element={<FormSuccess/>} />
			<Route path="/confirmation" element={<FormConfirm/>}/>
			<Route path="/" element={<FormStart/>}/>
			<Route path='*' element={<Error404/>}/>
		</Routes>
	</FormContextProvider>
)