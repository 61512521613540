import { useEffect, useState } from 'react';
import { Text, RadioGroupField, Radio } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../../../components/form/container/FormContainer';
import CritButton from "../../../ui-components/CritButton";
import { useFormContext } from '../../../contexts/form-context';

const FormStart = () => {
    const { formData, dispatch } = useFormContext();
    const navigate = useNavigate();

    const [radioValue, setRadioValue] = useState(formData.role ?? '');
    const [error, setError] = useState(false);
    const radioOptions = {
        'candidat': 'Candidat',
        'interimaire': 'Intérimaire',
        'collaborateur': 'Collaborateur permanent' ,
        'partenaire': 'Partenaire commercial / Prospect'
    }
    
    useEffect(() => {
        if(formData.role){
            setRadioValue(formData.role);
        }
    }, [])

    const handleChange = (e) => {
        dispatch({action: 'SET_FORM_DATA', payload: { [e.target.name]: e.target.value}})
        setRadioValue(e.target.value);
        if(error === true){
            setError(false);
        }
    }

    const handleConfirm = ()=>{
        if(!formData.role){
            setError(true);
            return;
        }
        navigate(`/${formData.role}`);
    } 
    
    return (
        <FormContainer>
            <Text className='justify'>
            Au sein de notre entreprise, la protection des données personnelles est un enjeu majeur.<br/>
            Vous pouvez exercer vos droits sur&nbsp;vos&nbsp;données&nbsp;personnelles, dans le respect des articles 15 et suivants du Règlement Général sur la Protection des Données en remplissant ce formulaire.
            </Text>
            <ul className='justify'>
                <li>
                Pour tout problème technique ou lié à l'accès à votre compte Crit-job, merci de faire votre demande via <a href="mailto:support.crit-job@groupe-crit.com">support.crit-job@groupe-crit.com</a>.
                </li>
                <br/>
                <li>
                Aucune autre demande ne peut être traitée via ce formulaire. Si vous souhaitez déposer une candidature, nous vous invitons à vous rendre sur l’espace candidat de notre site <a href="https://www.crit-job.com/">www.crit-job.com</a> ou à contacter l’une de nos agences.
                </li>
            </ul>
            
            <Text className='critCaption'>* Champs obligatoire</Text>
            <RadioGroupField className='requiredField' defaultValue={radioValue} onChange={handleChange} label="Vous êtes :" name="role" hasError={error} errorMessage="Veuillez renseigner un rôle">
            {
                Object.entries(radioOptions).map(entry => {
                    const [key, val] = entry;
                    return <Radio value={key} key={key}>{val}</Radio>
                })
            }
            </RadioGroupField>
            <CritButton textAlign="center" className='crit-button' overrides={{Button:{onClick:handleConfirm,children:'Suivant'}}}/>
        </FormContainer>
    )
}

export { FormStart };