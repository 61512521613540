/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, View } from "@aws-amplify/ui-react";
export default function ValidationIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "ValidationIcon")}
      {...rest}
    >
      <View
        width="96px"
        height="96px"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Ic\u00F4ns_app_Valider 1")}
      >
        <Icon
          width="62.53515625px"
          height="73.72882080078125px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 62.53515625,
            height: 73.72882080078125,
          }}
          paths={[
            {
              d: "M9.35438 43.9725C9.35438 22.785 26.535 5.61192 47.7056 5.61192C52.7972 5.61026 57.8382 6.62177 62.535 8.58754C55.7043 3.02099 47.1591 -0.0128661 38.3475 4.10139e-05C17.175 4.10139e-05 0 17.1732 0 38.3475C0.000397692 45.9053 2.23231 53.2947 6.41581 59.589C10.5993 65.8832 16.5482 70.8021 23.5163 73.7288C19.0887 70.1366 15.5195 65.6008 13.0694 60.4526C10.6192 55.3045 9.34997 49.674 9.35438 43.9725L9.35438 43.9725Z",
              fill: "rgba(255,106,20,0.25)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="10.05%"
          bottom="13.14%"
          left="10.05%"
          right="24.8%"
          {...getOverrideProps(overrides, "Vector32463242")}
        ></Icon>
        <Icon
          width="80.4375px"
          height="80.4376220703125px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 80.4375,
            height: 80.4376220703125,
          }}
          paths={[
            {
              d: "M40.2187 0.000125952C32.2637 -0.000596793 24.4872 2.35798 17.8728 6.77752C11.2585 11.1971 6.1035 17.479 3.05988 24.8287C0.0162735 32.1784 -0.779194 40.2657 0.774102 48.0675C2.3274 55.8694 6.15967 63.0353 11.7862 68.6589C27.4912 84.3639 52.9537 84.3639 68.6587 68.6589C84.3637 52.9539 84.3637 27.4914 68.6587 11.7864C64.9328 8.03909 60.5008 5.06792 55.6191 3.04482C50.7374 1.02172 45.503 -0.0131404 40.2187 0.000125952L40.2187 0.000125952ZM40.2187 76.7026C20.1074 76.7026 3.73867 60.3339 3.73867 40.2226C3.73867 20.1114 20.1112 3.74263 40.2187 3.74263C60.3262 3.74263 76.7043 20.1114 76.7043 40.2189C76.7043 60.3264 60.3337 76.7026 40.2187 76.7026Z",
              fill: "rgba(51,63,72,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="8.11%"
          bottom="8.11%"
          left="8.11%"
          right="8.11%"
          {...getOverrideProps(overrides, "Vector32463243")}
        ></Icon>
        <Icon
          width="43.4462890625px"
          height="27.99859619140625px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 43.4462890625,
            height: 27.99859619140625,
          }}
          paths={[
            {
              d: "M40.3579 0.445307L16.2041 23.5303L3.04538 10.3697C2.68725 10.0773 2.23334 9.92838 1.77164 9.95167C1.30993 9.97496 0.873318 10.1688 0.546429 10.4957C0.219539 10.8226 0.02566 11.2592 0.00237066 11.7209C-0.0209187 12.1826 0.128041 12.6366 0.420378 12.9947L14.8823 27.4566C15.0558 27.6313 15.2628 27.7694 15.4909 27.8625C15.719 27.9556 15.9634 28.0018 16.2098 27.9984C16.4489 28.0007 16.6862 27.9556 16.9079 27.8658C17.1296 27.776 17.3314 27.6433 17.5016 27.4753L42.9379 3.15843C43.2657 2.8093 43.4475 2.34796 43.446 1.86906C43.4445 1.39015 43.2597 0.929978 42.9297 0.582931C42.5997 0.235884 42.1494 0.0282718 41.6712 0.00268098C41.1929 -0.0229099 40.7231 0.135461 40.3579 0.445307Z",
              fill: "rgba(255,106,20,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="36.66%"
          bottom="34.18%"
          left="27.88%"
          right="26.86%"
          {...getOverrideProps(overrides, "Vector32463244")}
        ></Icon>
      </View>
    </Flex>
  );
}
