/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function CritMentionLegalesCandidatInterimaire(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "CritMentionLegalesCandidatInterimaire")}
      {...rest}
    >
      <Text
        fontFamily="Open Sans"
        fontSize="13px"
        fontWeight="400"
        color="rgba(51,63,72,1)"
        lineHeight="15.234375px"
        textAlign="justify"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Les données personnelles collectées au présent formulaire sont traitées par les sociétés de travail temporaire du Groupe CRIT – 6 rue Toulouse Lautrec – 75017 PARIS (CRIT, AB INTERIM, LES COMPAGNONS et LES VOLANTS, ci-après « la Société »), en qualité de responsables du traitement, aux fins de leur permettre de répondre à votre demande concernant vos données personnelles. Elles sont traitées sur la base d’une obligation légale et sont destinées à l’usage exclusif des services dédiés et/ou concernés par la gestion des demandes relatives aux données personnelles. Toutes les informations visées par ce formulaire sont nécessaires pour permettre d’assurer le traitement et le suivi de votre demande. Elles seront conservées pendant l’année civile de votre demande plus 5 années. L’absence, l’incomplétude ou l’inexactitude des réponses pourra avoir pour conséquence une incapacité de la Société à vous identifier et exercer les actions requises. Pour plus d’informations, vous pouvez vous reporter à notre politique de gestion des données personnelles disponible sur notre site www.crit-job.com ou dans nos agences."
        {...getOverrideProps(
          overrides,
          "Les donn\u00E9es personnelles collect\u00E9es au pr\u00E9sent formulaire sont trait\u00E9es par les soci\u00E9t\u00E9s de travail temporaire du Groupe CRIT \u2013 6 rue Toulouse Lautrec \u2013 75017 PARIS (CRIT, AB INTERIM, LES COMPAGNONS et LES VOLANTS, ci-apr\u00E8s \u00AB la Soci\u00E9t\u00E9 \u00BB), en qualit\u00E9 de responsables du traitement, aux fins de leur permettre de r\u00E9pondre \u00E0 votre demande concernant vos donn\u00E9es personnelles. Elles sont trait\u00E9es sur la base d\u2019une obligation l\u00E9gale et sont destin\u00E9es \u00E0 l\u2019usage exclusif des services d\u00E9di\u00E9s et/ou concern\u00E9s par la gestion des demandes relatives aux donn\u00E9es personnelles. Toutes les informations vis\u00E9es par ce formulaire sont n\u00E9cessaires pour permettre d\u2019assurer le traitement et le suivi de votre demande. Elles seront conserv\u00E9es pendant l\u2019ann\u00E9e civile de votre demande plus 5 ann\u00E9es. L\u2019absence, l\u2019incompl\u00E9tude ou l\u2019inexactitude des r\u00E9ponses pourra avoir pour cons\u00E9quence une incapacit\u00E9 de la Soci\u00E9t\u00E9 \u00E0 vous identifier et exercer les actions requises. Pour plus d\u2019informations, vous pouvez vous reporter \u00E0 notre politique de gestion des donn\u00E9es personnelles disponible sur notre site www.crit-job.com ou dans nos agences."
        )}
      ></Text>
    </Flex>
  );
}
